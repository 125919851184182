import { FeatureStateSymbol, IPageWillMountHandler, ViewMode, ViewModeSym } from '@wix/thunderbolt-symbols'
import { IFeatureState } from 'thunderbolt-feature-state'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { name } from './symbols'
import type { AnimationsPageState } from './types'
import { taskify } from '@wix/thunderbolt-commons'

export const AnimationsInit = withDependencies(
	[named(FeatureStateSymbol, name), ViewModeSym],
	(featureState: IFeatureState<AnimationsPageState>, viewMode: ViewMode): IPageWillMountHandler => {
		return {
			pageWillMount() {
				if (!featureState.get()) {
					const animatorManagerPromise = import(
						'./animatorManagerFactory' /* webpackChunkName: "animatorManagerFactory" */
					).then(({ createAnimatorManager }) => taskify(() => createAnimatorManager(viewMode)))
					featureState.update(() => ({ animatorManager: animatorManagerPromise }))
				}
			},
		}
	}
)
