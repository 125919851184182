import type { ImagePlaceholder, ImagePlaceholderData, ImagePlaceholderPageConfig } from './types'
import { IComponentPropsExtender } from 'feature-components'
import { IMAGE_PLACEHOLDER_COMPONENTS_TYPES } from './imagePlaceholderComponentTypes'

export default function createFactory(imageClientApi: any) {
	return (
		pageConfig: ImagePlaceholderPageConfig
	): IComponentPropsExtender<
		{ getPlaceholder: (imagePlaceholderData: ImagePlaceholderData) => ImagePlaceholder },
		unknown
	> => {
		const { isSEOBot, staticMediaUrl } = pageConfig

		const getPlaceholder = ({ fittingType, src, target }: ImagePlaceholderData): ImagePlaceholder => {
			const placeholder = imageClientApi.getPlaceholder(fittingType, src, target, {
				isSEOBot,
			})

			if (placeholder && placeholder.uri) {
				placeholder.uri = `${staticMediaUrl}/${placeholder.uri}`
			}

			return placeholder
		}

		return {
			componentTypes: IMAGE_PLACEHOLDER_COMPONENTS_TYPES,
			getExtendedProps: () => ({ getPlaceholder }),
		}
	}
}
